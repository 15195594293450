// @ts-nocheck

import Basemap from "@arcgis/core/Basemap";
import PictureMarkerSymbol from "@arcgis/core/symbols/PictureMarkerSymbol";
import BasemapGallery from "@arcgis/core/widgets/BasemapGallery";

import {
  Damaged,
  DamageNotSuspected,
  DamageSeen,
  DamageSuspected,
  NoDamageSeen,
  NotDamaged,
  PartialDamageSeen,
  PartiallyDamaged,
} from "assets/images";
import { customPopupFunction } from "./MapCustomFunctionsMobile";
import FeatureLayer from "@arcgis/core/layers/FeatureLayer";

export var damageSuspectedSymbol = new PictureMarkerSymbol({
  url: DamageSuspected,
  width: 34,
  height: 24,
});
export var damagedSymbol = new PictureMarkerSymbol({
  url: Damaged,
  width: 34,
  height: 24,
});
export var notDamagedSymbol = new PictureMarkerSymbol({
  url: NotDamaged,
  width: 34,
  height: 24,
});

export var damagedNotSuspectedSymbol = new PictureMarkerSymbol({
  url: DamageNotSuspected,
  width: 34,
  height: 24,
});

export var noDamageSeenSymbol = new PictureMarkerSymbol({
  url: NoDamageSeen,
  width: 34,
  height: 24,
});

export var damageSeenSymbol = new PictureMarkerSymbol({
  url: DamageSeen,
  width: 34,
  height: 24,
});

export var partialDamageSeenSymbol = new PictureMarkerSymbol({
  url: PartialDamageSeen,
  width: 34,
  height: 24,
});

export var partiallyDamagedSymbol = new PictureMarkerSymbol({
  url: PartiallyDamaged,
  width: 34,
  height: 24,
});

export const BridgeSymbol = {
  type: "unique-value", // autocasts as new UniqueValueRenderer()
  field: "infrastructure_status",
  defaultSymbol: {
    type: "picture-marker",
    url: NotDamaged,
    color: "transparent",

    outline: {
      color: "transparent",
      width: 1,
    },
  }, // autocasts as new SimpleFillSymbol()
  uniqueValueInfos: [
    {
      // All features with value of "ND" (NotDamaged) will be green
      value: "ND",
      symbol: notDamagedSymbol,
    },
    {
      // All features with value of "DS" (Modarate / Damage Suspected) will be yellow
      value: "DS",
      symbol: damageSuspectedSymbol,
    },
    {
      // All features with value of "DA" (SEVERE) will be red
      value: "DA",
      symbol: damagedSymbol,
    },
    {
      // All features with value of "DNS" (DamageNotSuspected) will be gray
      value: "DNS",
      symbol: damagedNotSuspectedSymbol,
    },
    {
      // All features with value of "DSE" (DamageSeen) will be red stripes
      value: "DSE",
      symbol: damageSeenSymbol,
    },
    {
      // All features with value of "DSE" (NoDamageSeen) will be green stripes
      value: "NDS",
      symbol: noDamageSeenSymbol,
    },
    {
      // All features with value of "PDS" (PartialDamageSeen) will be yellow stripes
      value: "PDS",
      symbol: partialDamageSeenSymbol,
    },
    {
      // All features with value of "PD" (Partially Damage) will be Orange
      value: "PD",
      symbol: partiallyDamagedSymbol,
    },
  ],
};

export const customPopup = {
  // autocasts as new PopupTemplate()
  title: "Bridges in {infrastructure_name}",
  outFields: ["*"],
  content: customPopupFunction,
  actions: [],
};

export const renderer = {
  type: "simple",
  symbol: {
    type: "simple-fill",
    color: "#8CB832",
    outline: {
      color: "white",
      width: 1,
    },
  },
};

export const rendererProps = {
  type: "unique-value", // autocasts as new UniqueValueRenderer()
  field: "infrastructure_status",
  defaultSymbol: {
    type: "simple-fill",
    color: "#D3D3D3",
    outline: {
      color: "transparent",
      width: 1,
    },
  }, // autocasts as new SimpleFillSymbol()
  uniqueValueInfos: [
    {
      // All features with value of "ND" will be green
      value: "ND",
      symbol: {
        type: "simple-fill", // autocasts as new SimpleFillSymbol()
        color: "#8CB832", // green
        outline: {
          color: "white",
          width: 1,
        },
      },
    },
    {
      // All features with value of "DS" will be yellow
      value: "DS",
      symbol: {
        type: "simple-fill", // autocasts as new SimpleFillSymbol()
        color: "#ECD37B", // yellow
        outline: {
          color: "white",
          width: 1,
        },
      },
    },
    {
      // All features with value of "DA" will be red
      value: "DA",
      symbol: {
        type: "simple-fill", // autocasts as new SimpleFillSymbol()
        color: "#FC6C72", // red
        outline: {
          color: "white",
          width: 1,
        },
      },
    },
    {
      // All features with value of "DNS" will be gray
      value: "DNS",
      symbol: {
        type: "simple-fill", // autocasts as new SimpleFillSymbol()
        color: "#AFAFAF", // gray
        outline: {
          color: "white",
          width: 1,
        },
      },
    },
    {
      // All features with value of "DSE" will be red with white stripe
      value: "DSE",
      symbol: {
        type: "simple-fill", // autocasts as new SimpleFillSymbol()
        color: "#FC6C72", // red with white stripe
        // color: "repeating-linear-gradient(-45deg, red, red 10px, #fff, #fff 12px)",
        outline: {
          color: "white",
          width: 1,
        },
      },
    },
    {
      // All features with value of "PDS" will be orange with white stripe
      value: "PDS",
      symbol: {
        type: "simple-fill", // autocasts as new SimpleFillSymbol()
        color: "#ECA47B", // orange with white stripe
        // color: "repeating-linear-gradient(-45deg, red, red 10px, #fff, #fff 12px)",
        outline: {
          color: "white",
          width: 1,
        },
      },
    },
    {
      // All features with value of "NDS" will be green with white stripe
      value: "NDS",
      symbol: {
        type: "simple-fill", // autocasts as new SimpleFillSymbol()
        color: "#8CB832", // green with white stripe
        outline: {
          color: "white",
          width: 1,
        },
      },
    },
    {
      // All features with value of "PD" will be orange
      value: "PD",
      symbol: {
        type: "simple-fill", // autocasts as new SimpleFillSymbol()
        color: "#ECD37B", // orange
        outline: {
          color: "white",
          width: 1,
        },
      },
    },
    
  ],
};

export const heatMapRenderer = {
  type: "heatmap",
  field: "count",

  colorStops: [
    { color: "#1f1f1f", ratio: 0 },
    { color: "#dcdcf7", ratio: 0.001 },
    { color: "#c6cdf9", ratio: 0.002 },
    { color: "#e2dff3", ratio: 0.003 },
    { color: "#c5f4f6", ratio: 0.004 },
    { color: "#ccffdc", ratio: 0.005 },
    { color: "#faffcc", ratio: 0.006 },
    { color: "#faffcc", ratio: 0.007 },
    { color: "#faffcc", ratio: 0.008 },
    { color: "#f3f6c6", ratio: 0.009 },
    { color: "#fbefca", ratio: 0.0095 },
    { color: "#f6d5c6", ratio: 0.011 },
    { color: "#facbcc", ratio: 0.09 },

    { color: "#facbcc", ratio: 0.01 },
  ],
  radius: 60,

  // maxDensity: 1,

  minDensity: 0,
};

export const JoinedLayerTemplate = {
  title: "{__OBJECTID}",
  content: [
    {
      type: "fields",
      fieldInfos: [
        {
          fieldName: `count`,
          label: "Number Of bridges",
          isEditable: false,
          tooltip: "",
          visible: true,
          format: null,
          stringFieldOption: "text-box",
        },
      ],
    },
  ],
};

// const grey = Basemap.fromId("dark-gray-vector");

function getEarthQuake() {
  let earthQuake = Basemap.fromId("streets-navigation-vector");
  return earthQuake;
}
function getFlood() {
  let flood = Basemap.fromId("streets-navigation-vector");
  return flood;
}

export const basemapGallery = (view) => {
  let earthQuake = getEarthQuake();
  let flood = getFlood();

  earthQuake.when(function (params: any) {
    earthQuake.title = "Earthquake Map";
  });
  flood.when(function (params: any) {
    flood.title = "Flood Map";
  });
  flood.referenceLayers = [
    new FeatureLayer({
      url: "https://services9.arcgis.com/RHVPKKiFTONKtxq3/arcgis/rest/services/Live_Stream_Gauges_v1/FeatureServer/0",
    }),
  ];
  earthQuake.referenceLayers = [
    new FeatureLayer({
      url: "https://services9.arcgis.com/RHVPKKiFTONKtxq3/arcgis/rest/services/USGS_Seismic_Data_v1/FeatureServer",
    }),
  ];

  return new BasemapGallery({
    view: view,
    label: "Layers",
    source: [Basemap.fromId("streets-navigation-vector"), flood, earthQuake],
    container: document.createElement("div"), // autocasts to LocalBasemapsSource
  });
};
