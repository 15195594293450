import PageNotFound from "components/errors/PageNotFound";
import routes from "constants/routes";
import MobileMap from "pages/dashboard/mobile/MobileMap";
import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";

// Top level application router.

const Router: React.FC = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path={routes.map_mobile} element={<MobileMap />} />
        <Route path={routes.remaining} element={<PageNotFound />} />
      </Routes>
    </BrowserRouter>
  );
};

export default Router;
