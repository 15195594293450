// @ts-nocheck

import ArcGISMap from "@arcgis/core/Map";
import MapView from "@arcgis/core/views/MapView";
import { Box, ChakraProvider } from "@chakra-ui/react";
import axios from "axios";
import TessalationMapMobile from "components/mobilemap/TessalationMapMobile";
import React, { useRef, useState } from "react";

const MobileMap: React.FC = () => {
  const [isSuccess, setIsSuccess] = useState(false);
  const [isButtonShown, setIsButtonShown] = useState(true);
  const mapRef = useRef<Map>(null);

  // const organizatinoAPI = new OrganizationResource();
  const [infrastructureLists, setInfrastructureLists] = useState({
    type: "FeatureCollection",
    name: "survey_repub",
    crs: {
      type: "name",
      properties: {
        name: "urn:ogc:def:crs:OGC:1.3:CRS84",
      },
    },
    features: [],
  });

  const webmap = useRef(
    new ArcGISMap({
      basemap: "streets-navigation-vector",
    })
  );
  const app = {
    map: webmap.current,
    center: [-111.95292894799996, 40.75033736100005],
    scale: 25000,
  };

  const view = useRef(new MapView(app));

  async function loadInfrastructure(params: any) {

    const config = {
      headers: {
        Authorization: params.token,
      },
    };

    await axios
      .get(
        "https://api.kihomac.us/api" +
          `/organizations/${params.orgId}/infrastructures-list/`,
        config
      )
      .then((response) => {

        let modified = response?.data.map((data: any, i: number) => {
          return {
            geometry: {
              type: "Point",
              coordinates: data?.geometry_json?.coordinates,
            },
            properties: data,
            type: "Feature",
          };
        });

        if(!isSuccess){
          view?.current.goTo(
            {
              center: [response?.data[0]?.geometry_json?.coordinates[0], response?.data[0]?.geometry_json?.coordinates[1]],
              zoom: 12,
            },
            {
              duration: 1000, // Duration of animation will be 5 seconds
            }
          );
        }

        setIsSuccess(true);
        setInfrastructureLists({
          type: "FeatureCollection",
          name: "survey_repub",
          crs: {
            type: "name",
            properties: {
              name: "urn:ogc:def:crs:OGC:1.3:CRS84",
            },
          },
          features: modified,
        });
      })
      .catch((error) => {
        console.error(JSON.stringify(error), "error while getting");
      });

    return;
  }

  function myFunction(a) {
    let p = JSON.parse(a);
    let pt = {
      latitude: p[0],
      longitude: p[1],
    };
    // console.log(pt.latitude, pt.longitude, "view in showLocation");
    // const { attributes, location, extent } = item;
    let opts = {
      duration: 500, // Duration of animation will be 5 seconds
    };
    // console.log(item);
    view.current.goTo(
      {
        center: [pt.latitude, pt.longitude],
        zoom: 12,
      },
      opts
    );
    // showLocation(view, pt);
  }

  function fetchInfrastructure(a) {
    // let p = JSON.parse(a);
    let b = JSON.parse(a);
    // console.log(b, "function called");
    loadInfrastructure(b);
  }

  function hideButtons() {
    setIsButtonShown(false);
    view.current?.ui.remove("zoom");
    view.current?.ui.remove("expand");
  }

  window.addEventListener("message", (event) => {
    if (event.data.type === "myFunction") {
      myFunction(event.data.name);
      window.postMessage({ type: "greetResponse" }, "*");
    } else if (event.data.type === "fetchInfrastructure") {
      fetchInfrastructure(event.data.name);
      window.postMessage({ type: "greetResponse" }, "*");
    } else if (event.data.type === "hideButtons") {
      hideButtons();
      window.postMessage({ type: "greetResponse" }, "*");
    }
  });

  return (
    <ChakraProvider>
      <Box display={"flex"} width="full" height={"full"} position="fixed">
        {isSuccess && (
          <TessalationMapMobile
            views={view.current}
            app={app}
            infrastructureLists={infrastructureLists}
            mapRef={mapRef}
            isButtonShown={isButtonShown}
          />
        )}
      </Box>
    </ChakraProvider>
  );
};
export default MobileMap;