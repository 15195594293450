// @ts-nocheck

import FeatureLayer from "@arcgis/core/layers/FeatureLayer";
import Feature from "@arcgis/core/widgets/Feature";

export function customPopupFunction(feature: Feature) {
  const div = document.createElement("div");

  window.bridgeClickEvent.triggerEvent(
    JSON.stringify(feature.graphic.attributes)
  );

  // console.log(JSON.stringify(feature.graphic.attributes), "image url");
  div.innerHTML = `<div style="display: flex; width="375px">
    <div style="width: 30%;">
      <img src="${
        feature.graphic.attributes.image
          ? feature.graphic.attributes.image
          : "https://media.istockphoto.com/id/117751351/photo/ravenel-bridge-in-charleston-sc.jpg?s=612x612&w=0&k=20&c=gd2DYSiomkQEswKcp-MPT3VvBMhwTY5HAqL7UEwfcXI="
      }" alt="Your Image" style="height: 100%;object-fit: cover;">
    </div>
    <div style="width: 70%; padding: 10px;">
      <div style="font-weight: 600;
        font-size: 16px;
        line-height: 22px;">${feature.graphic.attributes.bridge_name}
      </div>
      <div style="font-size:13px">${DateFormatYMD(
          feature.graphic.attributes.next_assessment_date
        )}
      </div>
      <div style="font-size:13px">${
          infrastructureStatuses[
            feature.graphic.attributes.infrastructure_status
          ]
        }
      </div>
    </div>
  </div>`;
  return div;
}

export async function createLayer(layer: any, source: any, extraFields: any) {
  // console.log("entered into  CreateLayer");

  await layer.load();

  const fieldInfosMap = new Map();

  for (const field of [...layer.fields]) {
    // console.log("loop inside inserting fieldinfos");
    fieldInfosMap.set(field.name, {
      fieldName: field.name,
      label: field.alias || field.name,
    });
  }
  const fieldInfos = [...new Set(fieldInfosMap.values())];

  const featLayer = new FeatureLayer({
    title: "SPATIAL JOIN",
    objectIdField: layer.objectIdField,
    fields: [...new Set([...layer.fields, ...extraFields])],
    geometryType: layer.geometryType,
    source,
    popupTemplate: {
      title: "Copy Layer",
      contentL: [
        {
          type: "fields",
          fieldInfos: [...fieldInfos.values()],
        },
      ],
    },
  });
  return featLayer;
}

export async function showLocation({ mapView }: any, item: any) {
  let opts = {
    duration: 5000, // Duration of animation will be 5 seconds
  };

  mapView?.goTo(
    {
      center: [item.latitude, item.longitude],
      zoom: 12,
    },
    opts
  );
}
