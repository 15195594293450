// @ts-nocheck
import { Box, Button } from "@chakra-ui/react";
import React, { useEffect } from "react";
import { IoLocationOutline } from "react-icons/io5";
import { basemapGallery } from "./MapConstantsMobile";

import MapView from "@arcgis/core/views/MapView";
import Expand from "@arcgis/core/widgets/Expand";

import { initialize } from "./MapViewMobile";

const TessalationMapMobile = React.memo(
  ({
    views,
    app,
    infrastructureLists,
    showButtons = true,
    mapRef,
    isButtonShown,
  }: // location,
  {
    views: MapView;
    app: any;
    infrastructureLists: any;
    showButtons?: boolean;
    location?: any;
    mapRef: any;
    isButtonShown: boolean;
  }) => {
    // eslint-disable-next-line
    // const [isButtonShown, setIsButtonShown] = useState(true);

    const loadMap = async (prop) => {
      if (!views.container && prop.features.length > 0) {
        views.ui?.remove("expand");
        views.map?.removeAll();

        const { container, geojsonlayerBridge, joinLayer } = await initialize(
          mapRef.current,
          views,
          app,
          prop
        );
        views.container = container;
        console.log(
          JSON.stringify(geojsonlayerBridge),
          "bridge layers before adding"
        );
        app.map.add(geojsonlayerBridge);

        // console.log(await a.queryFeatures(query), "join layer created");
        app.map.add(joinLayer);
        // app.map.add(a);

        let basemapGalleryExpand = new Expand({
          view: views,
          content: basemapGallery(views).domNode,
          id: "expand",
          // expandIconClass: "esri-icon-basemap",
        });

        // app.map.add(joinLayer);
        app.map.reorder(joinLayer, 10);
        if(isButtonShown){
          views.ui.add(basemapGalleryExpand, {
            position: "bottom-left",
          });
        }

      } else if (!views.container && prop.features.length === 0) {
        views.ui?.remove("expand");
        views.map?.removeAll();
        views.container = mapRef.current;
      } else {
        // console.log("destroying map");
        views.container = mapRef.current;
        // views.map.destroy();
      }
      // eslint-disable-next-line
    };

    const destroyMap = () => {
      views.destroy();
      // views = null;
    };

    useEffect(() => {
      loadMap(infrastructureLists);
      return () => {
        destroyMap();
        // console.log("leaving map view");
      };
      // eslint-disable-next-line
    }, []);

    // window.addEventListener("load", () => {
    //   window.hideButtons = () => {
    //     // setIsButtonShown(false);
    //     views?.ui.remove(["zoom", Expand]);
    //   };
    // });

    return (
      <Box display={"flex"} height="100%" width={"100%"}>
        <Box ref={mapRef} className="mapDiv" />
        {isButtonShown && showButtons && (
          <>
            <Button
              backgroundColor={"#FFFFFF"}
              className="mobile-filter-button"
              position={"absolute"}
            >
              Filter
            </Button>
            <Button
              backgroundColor={"#FFFFFF"}
              className="custom-button"
              position={"absolute"}
              p="0px"
            >
              <IoLocationOutline size={14} />
            </Button>
          </>
        )}
      </Box>
    );
  }
);

export default TessalationMapMobile;
//
