// @ts-nocheck

import "@arcgis/core/assets/esri/themes/light/main.css";
import * as geometryEngine from "@arcgis/core/geometry/geometryEngine";

import "App.css";

import { getGeoJsonLayer, getGeoJsonLayerBridge } from "./LayersMobile";
import { JoinedLayerTemplate, rendererProps } from "./MapConstantsMobile";
import { createLayer } from "./MapCustomFunctionsMobile";
import { query } from "./QueriesMobile";

export const initialize = async (
  container: any,
  views: any,
  app: any,
  infrastructureLists: any
) => {
  views.ui.move("zoom", "bottom-right");
  views.ui.remove("attribution");

  // console.log("infrastructureLists================",infrastructureLists )
  const geojsonlayer = getGeoJsonLayer(infrastructureLists);
  const geojsonlayerBridge = getGeoJsonLayerBridge(infrastructureLists);

  const features = [];

  try {
    // get features for layer and bridges
    const geoJsonResults = await geojsonlayer.queryFeatures(query);
    const geoJsonBridgeResult = await geojsonlayerBridge.queryFeatures(query);

    // assign to temp
    let temp = [...geoJsonBridgeResult.features]; // assign bridge feature array to temp
    // loop through layer features to get bridges inside it
    for (let feat of geoJsonResults.features) {
      // set default values
      feat.attributes.count = 0;
      feat.attributes.infrastructure_status = "";
      // loop through briges details
      for (let i = 0; i < temp.length; i++) {
        // get current index bridge
        const currentBridge = temp[i];

        if (
          currentBridge &&
          feat.geometry &&
          currentBridge.geometry &&
          geometryEngine.contains(feat.geometry, currentBridge.geometry) // checks for if bridge geometry lies inside current layer geometry
        ) {
          feat.attributes.infrastructure_status =
            currentBridge.attributes.infrastructure_status === "DS" ||
            feat.attributes.infrastructure_status === "DS"
              ? "DS"
              : currentBridge.attributes.infrastructure_status === "DA" ||
                feat.attributes.infrastructure_status === "DA"
              ? "DA"
              : currentBridge.attributes.infrastructure_status === "PD" ||
                feat.attributes.infrastructure_status === "PD"
              ? "PD"
              : currentBridge.attributes.infrastructure_status === "ND" ||
                feat.attributes.infrastructure_status === "ND"
              ? "ND"
              : currentBridge.attributes.infrastructure_status === "NDS" ||
                feat.attributes.infrastructure_status === "NDS"
              ? "NDS"
              : currentBridge.attributes.infrastructure_status === "DSE" ||
                feat.attributes.infrastructure_status === "DSE"
              ? "DSE"
              : currentBridge.attributes.infrastructure_status === "NDS" ||
                feat.attributes.infrastructure_status === "NDS"
              ? "NDS"
              : currentBridge.attributes.infrastructure_status === "PDS" ||
                feat.attributes.infrastructure_status === "PDS"
              ? "PDS"
              : feat.attributes.infrastructure_status;

          feat.attributes.count++;
          temp.splice(i, 1);
        }
      }
      features.push(feat);
    }

    app.map.removeMany([geojsonlayer]);
  } catch (err) {
    console.log(err, "error in views.when");
  }
  const joinLayer = await createLayer(geojsonlayer, features, [
    {
      name: "count",
      alist: "Count",
      type: "integer",
    },
    {
      name: "infrastructure_status",
      alist: "Infrastructure_status",
      type: "string",
    },
  ]);
  // console.log(joinLayer, "created join layerys");

  joinLayer.renderer = rendererProps;
  joinLayer.screenSizePerspectiveEnabled = true;

  joinLayer.opacity = 0.5;
  joinLayer.maxScale = 145500;
  joinLayer.popupTemplate = JoinedLayerTemplate;

  return { container, geojsonlayerBridge, joinLayer };
};

