import { ChakraProvider } from "@chakra-ui/react";
import "assets/css/App.css";
import "assets/css/theme.css";
import theme from "config/theme";
import ThemeProvider from "context/ThemeContext";
import Router from "router/Router";
import ErrorBoundary from "./errors/ErrorBoundary";

const App = () => {
  return (
    <ThemeProvider>
      <ChakraProvider theme={theme}>
        <ErrorBoundary>
          <Router />
        </ErrorBoundary>
      </ChakraProvider>
    </ThemeProvider>
  );
};

export default App;
