// @ts-nocheck
import GeoJSONLayer from "@arcgis/core/layers/GeoJSONLayer";
import Hexagon from "assets/polygons/Grid.json";
import { BridgeSymbol, customPopup } from "./MapConstantsMobile";

export const getGeoJsonLayerBridge = (BridgeList: any) => {
  const bridgeBlob = new Blob([JSON.stringify(BridgeList)], {
    type: "application/json",
  });
  const urlbridge = URL.createObjectURL(bridgeBlob);

  return new GeoJSONLayer({
    url: urlbridge,
    editingEnabled: true,
    screenSizePerspectiveEnabled: true,

    opacity: 1,
    minScale: 325500,
    renderer: BridgeSymbol,
    popupTemplate: customPopup,
  });
};

export const getGeoJsonLayer = (BridgeList: any) => {
  const bridgeBlob = new Blob([JSON.stringify(Hexagon)], {
    type: "application/json",
  });
  const url = URL.createObjectURL(bridgeBlob);

  return new GeoJSONLayer({
    url,

    opacity: 0.5,
    visible: true,
    popupEnabled: true,
    maxScale: 8,
  });
};
